import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableCompounding = loadable(() => import('components/CompoundingAsepticIsolatorsPage'), {
  fallback: (
    <Spinner />
  ),
});

const CompoundingIsolators = () => {
  return <LoadableCompounding />;
};

export default CompoundingIsolators;
